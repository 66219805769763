<template>
  <section>
    <div class="banner">
      <div class="banner-text">
        <div class="container">
          <h2 class="banner-title">Download</h2>
          <h2 class="banner-sub-title"></h2>
        </div>
      </div>
    </div>
    <section class="main-container">
      <div class="container">
        <div class="left">
          <div class="div">
            <div class="widget">
              <h3 class="widget-title"><span id="main-title-name">Download</span></h3>
            </div>
          </div>
        </div>
        <div class="right">
          <h3 class="sub-title">Download</h3>
          <div class="text-main">* Poster(s) must be written in the provided PPT Poster format. Please download and use the PPT Poster template.</div>
          <div>
            <a class="btn" href="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/04/18/b4138922e15f46b9bb60ab26e3b08003/ASCVTS2024- Poster Template.pptx"><a-icon type="download" /> Poster Template</a>
          </div>
          <div class="text-main">* Invitation letter from ASCVTS 2024 for your visa application.</div>
          <div>
            <a class="btn" href="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/04/19/01f633b07fff4025bb05ac4e787e0f35/Format%20VISA%20Invitation%20Letter-ASCVTS%202024.docx"><a-icon type="download" /> Invitation letter</a>
          </div>
          <div class="text-main">* Program</div>
          <div>
            <a class="btn" href="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/05/07/9e6614e2141b43ecb7fea3162d282f8f/Program.pdf"><a-icon type="download" /> Download</a>
          </div>
          <div class="text-main">* Conference Notice</div>
          <div>
            <a class="btn" href="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/05/07/d233b98fc6b64b238f6f9aeae3d549a8/Conference Notice.pdf"><a-icon type="download" /> Download</a>
          </div>

          <div class="text-main">* Slide presentation must be in the provided PPT format. Please download and use the PPT template.</div>
          <div>
            <a class="btn" href="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/05/10/60211765c4b3449e9b1dc237797a0f4a/ASCVTS2024课件模板.pptx"><a-icon type="download" /> PPT template</a>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name:'about',
  data() {
    return {
    }
  },
  methods: {
    redirectUrl(nav) {
      this.$router.push({name: nav.urlName})
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  min-height: 300px;
  color: #fff;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1) ), url('../../assets/img/about/banner.png');
  .banner-text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: translateY(-50%);
    .container {
      max-width: 1320px;
      margin: 0 auto;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased !important;
      .banner-title {
        color: #e8bd55;
        text-transform: uppercase;
        font-size: 58px;
        font-weight: 900;
      }
      .banner-sub-title{
        color: #fff;
        font-size: 48px;
        font-weight: 900;
      }
      @media (max-width:1320px) {
        padding: 0 15px;
      }
      @media (max-width:768px) {
        .banner-title {
          font-size: 48px;
        }
        .banner-sub-title {
          font-size: 38px;
        }
      }
    }
  }
}
.main-container {
  .container {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    .left {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 15px;
      .div {
        height: 100%;
        border-right: solid 1px #ddd;
        border-left: solid 1px #ddd;
        background-color: #f8f9fa;
        .widget {
          padding: 30px 15px 40px;
          position: sticky;
          top: 70px;
          .widget-title{
            font-size: 27px;
            font-weight: 900;
            position: relative;
            padding-left: 30px;
            margin: 0 -15px;
            background-color: #19317e;
            color: #fff;
            text-transform: uppercase;
          }
          .nav-tabs li {
            color: #444444;
            line-height: normal;
            a {
              position: relative;
              color: #444444;
              padding: 15px 0 15px 15px;
              font-weight: 600;
              border-bottom: 1px solid #ddd;
              display: block;
              transition: all .3s;
              font-size: 18px;
            }
            &:hover a{
              color: #1B3287;
            }
            &.active a {
              color: #1B3287;
              &::before {
                font-family: "iconfont";
                content:'\e6ae';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
    .right {
      min-height: 700px;
      flex: 0 0 75%;
      position: relative;
      padding: 0 15px 200px;
      .sub-title {
        font-weight: 900;
        font-size: 27px;
        line-height: normal;
        margin: 30px 0px 20px 0px;
        padding-bottom: 10px;
        border-bottom: solid 1px #ddd;
      }
      .container {
        font-size: 20px;
      }

    }
    @media (max-width:768px) {
        .left {
          display: none;
        }
        .right {
          flex: 1;
          padding: 0 15px 40px;
          .bottom {
            display: block;
            .bottom-lt {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
  }
}

       .text-main {
          font-family: "PublicSans Black";
          font-size: 16px;
        }
                .btn {
                  margin: 1em 0;
          display: inline-block;
          border-radius: 4px;
          font-size: 14px;
          line-height: 21px;
          border: 1px solid #007bff;
          color: #007bff;
          padding: 4px 8px;
          cursor: pointer;
          transition: .3s;
          &:hover {
            background-color: #007bff;
            color: #fff;
          }
          /deep/ .anticon {
            vertical-align: middle;
          }
        }
</style>
